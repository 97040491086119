/* Font */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700;500&display=swap");
/* Icons */
@import url("https://cdn.jsdelivr.net/npm/css.gg/icons/icons.css");

/* 
************ Resets & Body ************
*/

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

html,
body {
  color: #111111 !important;
  font-family: "Inter", serif, sans-serif, -apple-system, BlinkMacSystemFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f6f9;
  /* width: 1920px; */
  /* max-width: 1920px; */
  margin: 0 auto;
  overflow-x: hidden;
}

:root {
  --h1-font-size: 72px;
  --h1-line-height: 100%;
  --h2-font-size: 56px;
  --h2-line-height: 100%;
  --h3-font-size: 44px;
  --h3-line-height: 110%;
  --h4-font-size: 32px;
  --h4-line-height: 110%;
  --h5-font-size: 20px;
  --h5-line-height: 130%;
  --xxl-font-size: 102px;
  --xxl-line-height: 100%;
  --xl-font-size: 92px;
  --xl-line-height: 100%;
  --text-larger-font-size: 20px;
  --text-larger-line-height: 30px;
  --text-large-font-size: 18px;
  --text-large-line-height: 27px;
  --text-regular-font-size: 16px;
  --text-regular-line-height: 24px;
  --text-small-font-size: 14px;
  --text-small-line-height: 21px;
  --text-tiny-font-size: 12px;
  --text-tiny-line-height: 18px;
  --white: #fff;
  --dark-001: #000;
  --dark-002: #1b2128;
  --dark-003: #161a20;
  --dark-004: #2b3746;
  --grey-001: #516880;
  --grey-002: #d4d9df;
  --grey-003: #f1f4f7;
  --grey-004: #f6f8f9;
  --blue-brand: #0038ff;
  --blue-light-001: #4d74ff;
  --blue-light-002: #99afff;
  --blue-light-003: #e5ebff;
  --red-error: #f06;
  --green-success: #4dc16d;
  --color-text: var(--dark-004);
  --color-heading: var(--dark-001);
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}
