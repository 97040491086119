.contact-container {
  .contact-header {
    position: relative;
    margin: 0 0 20px 0;

    .contact-info {
      color: #232f47;
      padding: 3%;
      background: #ffffff;
      margin: 0 0 0 20px;
      top: 250px;
      position: absolute;

      h2 {
        color: #232f47;
        font-weight: 700;
      }
      p {
        color: #566e8c;
        font-weight: 400;
      }
    }



    .contact-image-header {
      img {
        width: 100%;
        height: 500px;
        object-fit: cover;
      }
    }
  }



  .form-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    /* box-shadow: 0 3px 18px rgba(0, 0, 0, 0.08); */
    width: 1300px;
    /* background: #1550c6; */
    color: #ffffff;

    .form-info {
      padding: 200px;
      .form-block {
        text-decoration: none;
      }
    }

    .form-contact {
      background: #ffffff;
      padding: 50px;
      width: 85%;

      h5 {
        display: block;
        margin: 10px 0 5px 0;
        color: #1a3b5d;
      }
    }
  }
}

@media (max-width: 500px) {
  .contact-container {
    /* border: 1px solid red; */
    width: 450px;
    .contact-header {
      /* position: relative; */
      /* margin: 0 0 20px 0; */
  
      .contact-info {
        color: #232f47;
        padding: 3%;
        background: #ffffff;
        width: 80%;
        align-items: center;
        /* margin: 0 0 0 20px; */
        /* top: 250px; */
        /* position: absolute; */
  
        h2 {
          color: #232f47;
          font-weight: 700;
        }
        p {
          color: #566e8c;
          font-weight: 400;
        }
      }
  
  
  
      .contact-image-header {
        img {
          width: 100%;
          height: 500px;
          object-fit: cover;
        }
      }
    }
  
  
  
    .form-container {
      /* border: 1px solid red; */
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      /* margin: 0 auto; */
      /* box-shadow: 0 3px 18px rgba(0, 0, 0, 0.08); */
      /* width: 1300px; */
      /* background: #1550c6; */
      color: #ffffff;
  
      .form-info {
        padding: 200px;
        .form-block {
          text-decoration: none;
        }
      }
  
      .form-contact {
        background: #ffffff;
        padding: 50px;
        width: 85%;
  
        h5 {
          display: block;
          margin: 10px 0 5px 0;
          color: #1a3b5d;
        }
      }
    }
  }
}

label {
  font-size: 16px;
  margin: 10px 0 5px 0;
  color: #1a3b5d;
  width: 100%;
  display: block;
  user-select: none;
}

input {
  border: 2px solid;
  color: #d9e1f6;
  width: 100%;
  height: 50px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
}

textarea {
  border: 2px solid;
  color: #d9e1f6;
  width: 100%;
  height: 200px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
}

/* Button Animations */

.contact-button {
  background: none;
  border: 2px solid #566e8c;
  font: inherit;
  line-height: 1;
  padding: 1em 2em;
  margin: 0.5em 0 0 0;
  border-radius: 0px;
  font-weight: 400;
  font-size: 14px;
}

.contact-button {
  border-color: var(--color);
  transition: 0.25s;
}
.contact-button:hover,
.contact-button:focus {
  border-color: var(--hover);
  color: #fff;
}

.slide:hover,
.slide:focus {
  box-shadow: inset 12.5em 0 0 0 var(--hover);
}

.slide {
  --color: #2364d2;
  --hover: #2364d2;
}

.contact-fail {
  margin: 0 auto;
  width: 80%;
}