@import url(https://fonts.googleapis.com/css?family=Montserrat);

.checkbox {
  margin: 0 auto;
}

.apply {
  width: 30%;
  height: 500px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  border-radius: 2%;
}

@media (max-width: 500px) {
  .apply {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    display: grid;
    place-items: center;
    border-radius: 2%;
  }

  .form-container-apply {
    width: 90% !important;
    height: 400px;
  }
}

body {
  margin: 0%;
  padding: 0%;
  font-family: montserrat, arial, verdana;
}

.form {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container-apply {
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  background: #2364d2;
  background: radial-gradient(
    circle,
    #2364d2,
    rgb(12, 23, 175) 84%,
    rgb(17, 0, 255) 100%
  );
}

.form-container-apply .header {
  color: white;
  flex: 20%;
  display: flex;
  align-items: center;
  /* place-items: center; */
  /* width: 50%; */
  margin: 0 auto;
}

.form-container-apply .body {
  flex: 60%;
}

.form-container-apply .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
}

.footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  font-weight: bold;
  color: #2364d2;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
}

.sign-up-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-up-container p {
  color: rgb(255, 255, 255);
  font-size: 22px;
}

.sign-up-container input {
  margin: 5px;
  width: 200px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
  background-color: white;
}

.personal-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.personal-info-container input {
  margin: 5px;
  width: 200px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

/* PROGRESS BAR */
.progressbar {
  width: 400px;
  height: 10px;
  background-color: white;
  margin-bottom: 50px;
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: rgb(244,140,4);
}

@media (max-width: 500px) {
  .progressbar {
    width: 80%;
    height: 10px;
    background-color: white;
    margin-bottom: 50px;
    /* border: 1px solid red; */
    /* align-items: center; */
  }
  
  .progressbar div {
    width: 33.3%;
    height: 100%;
    background-color: rgb(244,140,4);
  }
}


.other-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.other-info-container input {
  margin: 5px;
  width: 200px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

input {
  border: 2px solid rgb(98, 0, 255);
  border-radius: 5px;
}

input:focus {
  border: 3px solid rgb(98, 0, 255);
}

.footer {
    margin: 0 auto;
}

.errorText {
  color: red;
  margin: 0 auto;
}

h1 {
  color: white;
}